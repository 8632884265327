// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

@font-face {
    font-family: "FuturaBold";   /*Can be any text*/
    src: local("FuturaMdBTBold"),
      url("../fonts/Futura-Md-BT-Negrita.ttf") format("truetype");
}

@font-face {
    font-family: "FuturaCursiva";   /*Can be any text*/
    src: local("FuturaBkBTCursiva"),
      url("../fonts/Futura-Bk-BT-Cursiva.ttf") format("truetype");
}

@font-face {
    font-family: "FuturaNormal";   /*Can be any text*/
    src: local("FuturaBkBTNormal"),
      url("../fonts/Futura-Bk-BT-Normal.ttf") format("truetype");
}

@font-face {
    font-family: "FuturaNegrita";   /*Can be any text*/
    src: local("FuturaBkBTNegrita"),
      url("../fonts/Futura-Md-BT-Negrita.ttf") format("truetype");
}

@font-face {
    font-family: "FuturaCursivaMedia";   /*Can be any text*/
    src: local("FuturaBkBTCursivaMedia"),
      url("../fonts/Futura-Md-BT-Cursiva-media.ttf") format("truetype");
}


@font-face {
    font-family: "FuturaMediana";   /*Can be any text*/
    src: local("FuturaBkBTMediana"),
      url("../fonts/Futura-Md-BT-Mediana.ttf") format("truetype");
}